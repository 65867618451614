import { Tooltip } from 'react-svg-tooltip'
import cn from 'clsx'
import _max from 'lodash/max'
import _isFunction from 'lodash/isFunction'
import _isUndefined from 'lodash/isUndefined'

import { DataType, type Feature } from '../../types/data'

import './style.scss'

const BG_COLOR = '#fff'
const BORDER_COLOR = '#040444'
const FONT_SIZE = 16
const TEXT_COLOR = '#000'
const CHAR_WIDTH = 9
const VERTICAL_PADDING = 8
const HORIZONTAL_PADDING = 16
const TEXT_LINE_HEIGHT = FONT_SIZE + VERTICAL_PADDING
const CONTENT_ROW_VERTICAL_SPACING = FONT_SIZE + VERTICAL_PADDING
const DEFAULT_WIDTH = 256
const MIN_WIDTH = 200
const TOOLTIP_VERTICAL_POSITION_RELATIVE_TO_CURSOR = 24
const CLASS_NAME = 'cst-chart-bar-tooltip'

type ChartTooltipProps = {
  className?: string
  feature?: Feature
  x: number
  y: number
  dx: string | Date | number
  dy: number
  width: number
  triggerRef?: any
  formatX?: (x: string | Date | number) => string
  formatY?: (y: number, dataType: DataType) => string
  labelX?: string
  labelY?: string
  persistent?: boolean
  dataIsIntensity?: boolean
  showDate?: boolean
}

type SvgTextElementAttributes = {
  x: number
  y: number
  fontSize: number
  fill: string
}

const getSvgTextAttributes = (
  tooltipPositionX: number,
  tooltipPositionY: number,
  indexOfRow: number
): SvgTextElementAttributes => ({
  x: tooltipPositionX + HORIZONTAL_PADDING,
  y:
    tooltipPositionY +
    VERTICAL_PADDING +
    FONT_SIZE +
    CONTENT_ROW_VERTICAL_SPACING * indexOfRow,
  fontSize: FONT_SIZE,
  fill: TEXT_COLOR
})

export default function ChartTooltip({
  dataIsIntensity,
  triggerRef,
  persistent,
  className,
  feature,
  formatX,
  formatY,
  labelX,
  labelY,
  dx,
  dy,
  x,
  y,
  showDate = false
}: ChartTooltipProps) {
  const { properties } = feature ?? {}
  const {
    country_code: countryCode,
    land_cover: landCover,
    site_name: siteName,
    name,
    area
  } = properties ?? {}

  let contentByRows = []

  if (_isUndefined(feature)) {
    const dataType =
      dataIsIntensity === true ? DataType.Intensity : DataType.NEE

    // prettier-ignore
    contentByRows = [
      `${labelX ?? 'X'}: ${_isFunction(formatX) ? formatX(dx) : String(dx)}`,
      `${labelY ?? 'Y'}: ${_isFunction(formatY) ? formatY(dy, dataType) : Number(dy).toFixed(6)
      }`
    ]
  } else {
    const unitPrefix = Math.abs(dy) > 1000 ? 'kt' : 't'
    const unit =
      dataIsIntensity === true ? `${unitPrefix}CO2/ha` : `${unitPrefix}CO2`

    contentByRows = [
      `Country: ${countryCode}`,
      `Land Cover: ${landCover}`,
      `Site Name: ${siteName}`,
      `Name: ${name}`,
      `Area: ${(area ?? 0).toFixed(2)} (ha)`,
      `Flux: ${(Math.abs(dy) > 1000 ? dy / 1000 : dy).toFixed(2)} ${unit}`,
      ...(showDate ? [`Date: ${formatX?.(dx)}`] : [])
    ]
  }

  const maxTextLength = _max(contentByRows.map((str: string) => str.length))
  const height = contentByRows.length * TEXT_LINE_HEIGHT + VERTICAL_PADDING * 2
  const width = _isUndefined(maxTextLength)
    ? DEFAULT_WIDTH
    : Math.max(MIN_WIDTH, maxTextLength * CHAR_WIDTH)

  const tooltipPositionX = x - width / 2
  const tooltipPositionY = y + TOOLTIP_VERTICAL_POSITION_RELATIVE_TO_CURSOR

  const content = (
    <>
      <rect
        x={tooltipPositionX}
        y={tooltipPositionY}
        width={width}
        height={height}
        rx={0.5}
        ry={0.5}
        fill={BG_COLOR}
        stroke={BORDER_COLOR}
      />
      {contentByRows.map((textStr: string, index: number) => (
        <text
          key={index}
          {...getSvgTextAttributes(tooltipPositionX, tooltipPositionY, index)}
        >
          {textStr}
        </text>
      ))}
    </>
  )

  if (persistent === true) {
    return content
  }

  return (
    <Tooltip className={cn(CLASS_NAME, className)} triggerRef={triggerRef}>
      {content}
    </Tooltip>
  )
}
