import React from 'react'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@uidotdev/usehooks'

import * as H from 'hooks'
import { GroupMode } from 'types/ui'
import GroupList from 'components/GroupList/GroupList'
import PolygonList from 'components/PolygonList/PolygonList'
import NoDataSkeleton from 'components/NoDataSkeleton/NoDataSkeleton'

import { CLASS_NAME } from './const'
import { propTypes, defaultProps } from './props'
import { type AnalyticsSideBarProps } from './types'

import './style.scss'

const GROUP_LIST_ITEM_WIDTH_PX = 430

const AnalyticsSideBar: React.FC<AnalyticsSideBarProps> = (
  props: AnalyticsSideBarProps
) => {
  const {
    filters,
    emissions,
    className,
    selectedGroups,
    groupedEmissions,
    selectedEmissions,
    onShowFeatureInfo,
    setSelectedEmissions
  } = props

  const { groupMode } = filters
  const { t } = useTranslation()
  const { height: windowHeight } = useWindowSize()
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    grouped: groupMode !== GroupMode.None
  })

  return (
    <div className={finalClassName}>
      {emissions.length === 0 && <NoDataSkeleton />}
      {emissions.length > 0 && groupMode === GroupMode.None && (
        <PolygonList
          title={t('analytics_sidebar.feature_list_title')}
          emissions={emissions}
          onShowFeatureInfo={onShowFeatureInfo}
          selectedEmissions={selectedEmissions}
          setSelectedEmissions={setSelectedEmissions}
          scrollWindowWidth={508}
          scrollWindowHeight={windowHeight - 232}
          subTitle={
            _isEmpty(selectedEmissions)
              ? t('analytics_sidebar.feature_list_subtitle_empty')
              : `${
                  selectedEmissions?.length ??
                  t('analytics_sidebar.feature_list_subtitle_selected_none')
                } ${t(
                  'analytics_sidebar.feature_list_subtitle_selected_suffix'
                )}`
          }
          showSelectionIndex
        />
      )}

      {emissions.length > 0 && groupMode !== GroupMode.None && (
        <GroupList
          setSelectedEmissions={setSelectedEmissions}
          selectedEmissions={selectedEmissions}
          itemWidthPX={GROUP_LIST_ITEM_WIDTH_PX}
          groups={groupedEmissions}
          scrollWindowWidth={508}
          scrollWindowHeight={windowHeight - 232}
          subTitle={
            _isEmpty(selectedGroups)
              ? t('analytics_sidebar.group_list_subtitle_empty')
              : `${
                  selectedGroups?.length ??
                  t('analytics_sidebar.group_list_subtitle_selected_none')
                } ${t('analytics_sidebar.group_list_subtitle_selected_suffix')}`
          }
          showSelectionIndex
        />
      )}
    </div>
  )
}

AnalyticsSideBar.propTypes = propTypes
AnalyticsSideBar.defaultProps = defaultProps

export default AnalyticsSideBar
export { CLASS_NAME }
export type { AnalyticsSideBarProps as PROPS }
