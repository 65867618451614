/* eslint @typescript-eslint/indent: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */

import React, {
  useCallback,
  useContext,
  Fragment,
  useState,
  useMemo,
  useRef
} from 'react'
import cn from 'clsx'
import _map from 'lodash/map'
import _flatten from 'lodash/flatten'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'
import { useTranslation } from 'react-i18next'

import * as CSV from 'csv'
import * as U from 'utils'
import * as H from 'hooks'
import * as OU from './utils'
import * as OH from './hooks'
import { CHART_COLORS } from 'config'
import { getLegendDimensions } from '../ChartLegend/utils'
import FluidContentWrapperContext from '../FluidContentWrapper/context'
import { type Emissions, type ChartDataPoint, type Dimensions } from 'types'

import { defaultProps } from './props'
import { type LineChartProps } from './types'
import { PATH_ELEMENT_CLASS_NAME, CLASS_NAME } from './const'

import ChartHeader from 'components/ChartHeader/ChartHeader'
import ChartLegend from 'components/ChartLegend/ChartLegend'
import ChartTooltip from 'components/ChartTooltip/ChartTooltip'
import ChartAxisLeft from 'components/ChartAxisLeft/ChartAxisLeft'
import ChartAxisBottom from 'components/ChartAxisBottom/ChartAxisBottom'
import ChartAxisGridLines from 'components/ChartAxisGridLines/ChartAxisGridLines'

import './style.scss'

const MONTH_STRING_MAPPING = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december'
}

const getDataPointsFromSvgPath = (pathString: string): ChartDataPoint[] => {
  const allPoints = pathString
    .replace(/[MLT]/g, '')
    .trim()
    .split(/\s+/)
    .map(
      (str: string): ChartDataPoint => ({
        x: Number(str.split(',')[0]),
        y: Number(str.split(',')[1])
      })
    )

  // the first and the last coordinates are duplicated, so we are removing them
  return allPoints.slice(1, allPoints.length - 1)
}

const tooltipFormatX = (t: any, x: string | number | Date): string =>
  `${t(
    `months.${MONTH_STRING_MAPPING[new Date(x).getMonth() as keyof typeof MONTH_STRING_MAPPING]}`
  )} ${new Date(x).getFullYear()}`

interface TooltipData {
  emissions: Emissions | null
  x: string | Date | number
  y: number
  dx: string | Date | number
  dy: number
}

const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
  const {
    height: aHeight,
    className: aClassName,
    marginTop: aMarginTop,
    marginLeft: aMarginLeft,
    marginRight: aMarginRight,
    marginBottom: aMarginBottom,
    extraHeaderControls,
    tooltipEmissions,
    dataIsIntensity,
    tooltipFormatY,
    extraControls,
    temporal,
    labelX,
    labelY,
    split,
    title,
    data
  } = props

  const { t } = useTranslation()
  const { width: contentWrapperWidth } = useContext(FluidContentWrapperContext)
  const svgRef = useRef<any>(null)
  const containerRef = useRef<any>(null)
  const flatData = useMemo((): ChartDataPoint[] => {
    const d: ChartDataPoint[] =
      split === true
        ? _flatten(data as ChartDataPoint[][])
        : [...(data as ChartDataPoint[])]

    return d
  }, [split, data])

  const min = H.useFunctionMemo<number | undefined>(
    U.getEmissionsDataMinY,
    data
  )
  const max =
    H.useFunctionMemo<number | undefined>(U.getEmissionsDataMaxY, data) ??
    Infinity
  const marginTop = U.getFiniteWithDefault(aMarginTop, 0)
  const marginRight = U.getFiniteWithDefault(aMarginRight, 0)
  const marginBottom = U.getFiniteWithDefault(aMarginBottom, 0)
  const marginLeft = U.getFiniteWithDefault(aMarginLeft, 0)
  const width = contentWrapperWidth - marginLeft - marginRight
  const height = aHeight + marginTop + marginBottom * 2
  const scaleX = OH.useScaleX(
    flatData,
    width - marginLeft - marginRight,
    temporal
  )

  const scaleY = OH.useScaleY(min ?? 0, max ?? 0, aHeight)
  const className = H.useClassName(CLASS_NAME, aClassName)
  const paths = useMemo((): string[] => {
    if (split === true) {
      return (data as ChartDataPoint[][]).map((d) =>
        OU.genLinePathString(d, scaleX, scaleY)
      )
    } else {
      return [OU.genLinePathString(data as ChartDataPoint[], scaleX, scaleY)]
    }
  }, [scaleX, scaleY, data, split])

  // NOTE: We keep one tooltip ref active, and set it on circle element hover
  const tooltipRef = useRef<any>()
  const [activeTooltipData, setActiveTooltipData] =
    useState<TooltipData | null>(null)

  // NOTE: We need the x coordinate from the path, and the y coord from the
  // underlying data point for the tooltip
  const onPathPointMouseOver = useCallback(
    (x: string | Date | number, y: number, i: number, j: number) => {
      const emissions = _isUndefined(tooltipEmissions)
        ? null
        : tooltipEmissions[i]

      const d =
        split === true
          ? (data[i] as ChartDataPoint[])
          : (data as ChartDataPoint[])

      const dx = d[j].x
      const dy = d[j].y
      setActiveTooltipData({ emissions, x, y, dx, dy })
    },
    [setActiveTooltipData, tooltipEmissions, paths]
  )

  const onPathPointMouseOut = useCallback((): void => {
    setActiveTooltipData(null)
  }, [setActiveTooltipData])

  const onExportCSV = useCallback((): void => {
    const csv = CSV.generateChartCSV({
      t,
      data,
      emissions: tooltipEmissions,
      chartTitle:
        dataIsIntensity === true
          ? t('export.title_intensity')
          : t('export.title_nee')
    })

    const csvFilename =
      split === true
        ? `${t('export.groups_file_name_prefix')} ${data.length} ${t(
            'export.groups_file_name_suffix'
          )}`
        : t('export.file_name')

    U.downloadCSV(csv, csvFilename)
  }, [data, dataIsIntensity, tooltipEmissions, t, split])

  const onExportPNG = useCallback(() => {
    const { current } = containerRef

    // eslint-disable-next-line github/no-then,@typescript-eslint/no-unsafe-argument
    U.exportElementToPNG(current, height + 100, 'result.png').catch(
      (err: Error) => {
        console.error(err)
      }
    )
  }, [height, containerRef.current])

  const onExportJPG = useCallback(() => {
    const { current } = containerRef

    // eslint-disable-next-line github/no-then,@typescript-eslint/no-unsafe-argument
    U.exportElementToJPG(current, height + 100, 'result.jpg').catch(
      (err: Error) => {
        console.error(err)
      }
    )
  }, [height, containerRef.current])

  const [pathsHoveredStatuses, setPathsHoveredStatuses] = useState<boolean[]>(
    []
  )

  const onEmissionsHovered = useCallback(
    (i: number, isHovered: boolean): void => {
      if (pathsHoveredStatuses[i] === isHovered) {
        return
      }

      const nextPathsHoveredStatuses = [...pathsHoveredStatuses]
      nextPathsHoveredStatuses[i] = isHovered
      setPathsHoveredStatuses(nextPathsHoveredStatuses)
    },
    [setPathsHoveredStatuses]
  )

  const chartLegendDimensions = useMemo((): Dimensions => {
    if (paths.length < 1) {
      return { width: 0, height: 0 }
    }

    return paths.length < 1
      ? { width: 0, height: 0 }
      : getLegendDimensions(width, _map(tooltipEmissions ?? [], 'feature'))
  }, [paths, width, tooltipEmissions])

  const { height: chartLegendHeight } = chartLegendDimensions

  return (
    <div
      ref={containerRef}
      className={cn(className, {
        hovering: pathsHoveredStatuses.includes(true)
      })}
      style={{
        marginBottom: `${chartLegendHeight}px`
      }}
    >
      <ChartHeader
        title={title}
        onExportCSV={onExportCSV}
        onExportPNG={onExportPNG}
        onExportJPG={onExportJPG}
        controls={extraHeaderControls}
      />

      <div className="cst-line-chart-wrapper">
        <svg width={width + 64} height={height + 32} ref={tooltipRef}>
          <g
            transform={`translate(${marginLeft * 2}, ${marginTop})`}
            ref={svgRef}
          >
            <ChartAxisBottom
              scale={scaleX}
              label={labelX}
              chartWidth={width}
              chartHeight={height}
              transform={`translate(0, ${height - marginTop - marginBottom})`}
            />

            <ChartAxisLeft scale={scaleY} label={labelY} />
            <ChartAxisGridLines scaleX={scaleX} scaleY={scaleY} />

            {paths.map((path: string, i: number) => {
              if (path.length === 0) {
                const dataPoint =
                  split === true
                    ? (data as ChartDataPoint[][])[i][0]
                    : (data as ChartDataPoint[])[0]

                const { x, y } = dataPoint ?? {}

                if (!_isFinite(+x) || !_isFinite(y)) {
                  return null
                }

                const dx = scaleX(x) as number
                const dy = scaleY(y) as number

                return (
                  <circle
                    onMouseOut={onPathPointMouseOut}
                    onMouseOver={onPathPointMouseOver.bind(null, dx, dy, 0, 0)}
                    className={cn(PATH_ELEMENT_CLASS_NAME, {
                      hovered: pathsHoveredStatuses[i],
                      highlight: true
                    })}
                    fill={CHART_COLORS[i % CHART_COLORS.length]}
                    cx={dx}
                    cy={dy}
                    r={3}
                  />
                )
              }

              return (
                <Fragment key={`path-${i}`}>
                  <path
                    fill="none"
                    strokeWidth={1.5}
                    stroke={CHART_COLORS[i % CHART_COLORS.length]}
                    className={cn(PATH_ELEMENT_CLASS_NAME, {
                      hovered: pathsHoveredStatuses[i]
                    })}
                    key={`path-${i}`}
                    d={path}
                  />

                  {getDataPointsFromSvgPath(path).map(({ x, y }, j: number) => (
                    <circle
                      onMouseOut={onPathPointMouseOut}
                      onMouseOver={onPathPointMouseOver.bind(null, x, y, i, j)}
                      className={cn(PATH_ELEMENT_CLASS_NAME, {
                        hovered: pathsHoveredStatuses[i]
                      })}
                      fill={CHART_COLORS[i % CHART_COLORS.length]}
                      key={`circle-${j}`}
                      cx={x as number}
                      cy={y as number}
                      r={3}
                    />
                  ))}
                </Fragment>
              )
            })}

            {activeTooltipData !== null && (
              <ChartTooltip
                width={200}
                labelX={labelX}
                labelY={labelY}
                formatX={tooltipFormatX.bind(null, t)}
                formatY={tooltipFormatY}
                x={+activeTooltipData.x}
                y={+activeTooltipData.y}
                dx={activeTooltipData.dx}
                dy={activeTooltipData.dy}
                dataIsIntensity={dataIsIntensity}
                feature={activeTooltipData?.emissions?.feature}
                persistent
                showDate
              />
            )}

            {paths.length > 1 && (
              <ChartLegend
                x={0}
                y={height + 32}
                chartWidth={width}
                emissions={tooltipEmissions ?? []}
                setEmissionsHovered={onEmissionsHovered}
              />
            )}
          </g>
        </svg>
      </div>

      {!_isUndefined(extraControls) && extraControls}
    </div>
  )
}

LineChart.defaultProps = defaultProps

export default LineChart
